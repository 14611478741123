/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TopicWithArticlesResponsePage } from '../../models/topic-with-articles-response-page';

export interface ListTopics$Params {
  query?: string;
  publisherId?: Array<string>;
  excludeArticleIds?: Array<string>;
  start?: string;
  end?: string;
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'name' | '-name' | 'title' | '-title' | 'shortSummary' | '-shortSummary' | 'longSummary' | '-longSummary' | 'date' | '-date' | 'data' | '-data' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'>;
}

export function listTopics(http: HttpClient, rootUrl: string, params?: ListTopics$Params, context?: HttpContext): Observable<StrictHttpResponse<TopicWithArticlesResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listTopics.PATH, 'get');
  if (params) {
    rb.query('query', params.query, {"style":"form"});
    rb.query('publisherId', params.publisherId, {"style":"form"});
    rb.query('excludeArticleIds', params.excludeArticleIds, {"style":"form"});
    rb.query('start', params.start, {"style":"form"});
    rb.query('end', params.end, {"style":"form"});
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TopicWithArticlesResponsePage>;
    })
  );
}

listTopics.PATH = '/v0/topics';
