/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArticleWithTopicsResponsePage } from '../../models/article-with-topics-response-page';

export interface ListArticles$Params {
  minTrustScore?: number;
  maxTrustScore?: number;
  minPolarization?: number;
  maxPolarization?: number;
  minVariations?: number;
  maxVariations?: number;
  publisherIds?: Array<string>;
  topicIds?: Array<string>;
  canonicalLink?: string;
  from?: string;
  to?: string;
  offset?: number;
  limit?: number;
  sorting?: Array<'id' | '-id' | 'body' | '-body' | 'canonicalLink' | '-canonicalLink' | 'title' | '-title' | 'summary' | '-summary' | 'date' | '-date' | 'trustScore' | '-trustScore' | 'polarization' | '-polarization' | 'variations' | '-variations' | 'link' | '-link' | 'data' | '-data' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt' | 'rawContentId' | '-rawContentId'>;
}

export function listArticles(http: HttpClient, rootUrl: string, params?: ListArticles$Params, context?: HttpContext): Observable<StrictHttpResponse<ArticleWithTopicsResponsePage>> {
  const rb = new RequestBuilder(rootUrl, listArticles.PATH, 'get');
  if (params) {
    rb.query('minTrustScore', params.minTrustScore, {"style":"form"});
    rb.query('maxTrustScore', params.maxTrustScore, {"style":"form"});
    rb.query('minPolarization', params.minPolarization, {"style":"form"});
    rb.query('maxPolarization', params.maxPolarization, {"style":"form"});
    rb.query('minVariations', params.minVariations, {"style":"form"});
    rb.query('maxVariations', params.maxVariations, {"style":"form"});
    rb.query('publisherIds', params.publisherIds, {"style":"form"});
    rb.query('topicIds', params.topicIds, {"style":"form"});
    rb.query('canonicalLink', params.canonicalLink, {"style":"form"});
    rb.query('from', params.from, {"style":"form"});
    rb.query('to', params.to, {"style":"form"});
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArticleWithTopicsResponsePage>;
    })
  );
}

listArticles.PATH = '/v0/articles';
